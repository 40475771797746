/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPageTable = /* GraphQL */ `
  mutation CreatePageTable(
    $input: CreatePageTableInput!
    $condition: ModelPageTableConditionInput
  ) {
    createPageTable(input: $input, condition: $condition) {
      id
      table
      pageId
      microservice
      resource
      pageBreadcrumbs
      title
      shortDescription
      description
      search
      searchPlaceholder
      banner
      bannerSmall
      widgetId
      newsId
      breadcrumbsId
      featureCollectionId
      enabled
      menu
      metadata
      description1
      description2
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePageTable = /* GraphQL */ `
  mutation UpdatePageTable(
    $input: UpdatePageTableInput!
    $condition: ModelPageTableConditionInput
  ) {
    updatePageTable(input: $input, condition: $condition) {
      id
      table
      pageId
      microservice
      resource
      pageBreadcrumbs
      title
      shortDescription
      description
      search
      searchPlaceholder
      banner
      bannerSmall
      widgetId
      newsId
      breadcrumbsId
      featureCollectionId
      enabled
      menu
      metadata
      description1
      description2
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePageTable = /* GraphQL */ `
  mutation DeletePageTable(
    $input: DeletePageTableInput!
    $condition: ModelPageTableConditionInput
  ) {
    deletePageTable(input: $input, condition: $condition) {
      id
      table
      pageId
      microservice
      resource
      pageBreadcrumbs
      title
      shortDescription
      description
      search
      searchPlaceholder
      banner
      bannerSmall
      widgetId
      newsId
      breadcrumbsId
      featureCollectionId
      enabled
      menu
      metadata
      description1
      description2
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createItemFeatureTable = /* GraphQL */ `
  mutation CreateItemFeatureTable(
    $input: CreateItemFeatureTableInput!
    $condition: ModelItemFeatureTableConditionInput
  ) {
    createItemFeatureTable(input: $input, condition: $condition) {
      id
      table
      itemFeatureId
      microservice
      resourceFrom
      pageBreadcrumbs
      title
      shortDescription
      description
      banner
      bannerSmall
      widgetId
      newsId
      metadata
      enabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateItemFeatureTable = /* GraphQL */ `
  mutation UpdateItemFeatureTable(
    $input: UpdateItemFeatureTableInput!
    $condition: ModelItemFeatureTableConditionInput
  ) {
    updateItemFeatureTable(input: $input, condition: $condition) {
      id
      table
      itemFeatureId
      microservice
      resourceFrom
      pageBreadcrumbs
      title
      shortDescription
      description
      banner
      bannerSmall
      widgetId
      newsId
      metadata
      enabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteItemFeatureTable = /* GraphQL */ `
  mutation DeleteItemFeatureTable(
    $input: DeleteItemFeatureTableInput!
    $condition: ModelItemFeatureTableConditionInput
  ) {
    deleteItemFeatureTable(input: $input, condition: $condition) {
      id
      table
      itemFeatureId
      microservice
      resourceFrom
      pageBreadcrumbs
      title
      shortDescription
      description
      banner
      bannerSmall
      widgetId
      newsId
      metadata
      enabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEnterpriseFeature = /* GraphQL */ `
  mutation CreateEnterpriseFeature(
    $input: CreateEnterpriseFeatureInput!
    $condition: ModelEnterpriseFeatureConditionInput
  ) {
    createEnterpriseFeature(input: $input, condition: $condition) {
      id
      slugname
      avatar
      name
      legalregister
      verified
      geolocation
      url
      metadata
      enabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEnterpriseFeature = /* GraphQL */ `
  mutation UpdateEnterpriseFeature(
    $input: UpdateEnterpriseFeatureInput!
    $condition: ModelEnterpriseFeatureConditionInput
  ) {
    updateEnterpriseFeature(input: $input, condition: $condition) {
      id
      slugname
      avatar
      name
      legalregister
      verified
      geolocation
      url
      metadata
      enabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEnterpriseFeature = /* GraphQL */ `
  mutation DeleteEnterpriseFeature(
    $input: DeleteEnterpriseFeatureInput!
    $condition: ModelEnterpriseFeatureConditionInput
  ) {
    deleteEnterpriseFeature(input: $input, condition: $condition) {
      id
      slugname
      avatar
      name
      legalregister
      verified
      geolocation
      url
      metadata
      enabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProfileFeature = /* GraphQL */ `
  mutation CreateProfileFeature(
    $input: CreateProfileFeatureInput!
    $condition: ModelProfileFeatureConditionInput
  ) {
    createProfileFeature(input: $input, condition: $condition) {
      id
      username
      avatar
      name
      birthday
      age
      gender
      geolocation
      verified
      metadata
      enabled
      auth_signup_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProfileFeature = /* GraphQL */ `
  mutation UpdateProfileFeature(
    $input: UpdateProfileFeatureInput!
    $condition: ModelProfileFeatureConditionInput
  ) {
    updateProfileFeature(input: $input, condition: $condition) {
      id
      username
      avatar
      name
      birthday
      age
      gender
      geolocation
      verified
      metadata
      enabled
      auth_signup_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProfileFeature = /* GraphQL */ `
  mutation DeleteProfileFeature(
    $input: DeleteProfileFeatureInput!
    $condition: ModelProfileFeatureConditionInput
  ) {
    deleteProfileFeature(input: $input, condition: $condition) {
      id
      username
      avatar
      name
      birthday
      age
      gender
      geolocation
      verified
      metadata
      enabled
      auth_signup_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFeatureCollectionTable = /* GraphQL */ `
  mutation CreateFeatureCollectionTable(
    $input: CreateFeatureCollectionTableInput!
    $condition: ModelFeatureCollectionTableConditionInput
  ) {
    createFeatureCollectionTable(input: $input, condition: $condition) {
      id
      name
      type
      label
      title
      description
      featureFilters
      features
      pageId
      createdAt
      updatedAt
      metadata
      table
      featureCollectionId
      microservice
      resourceFrom
      pageBreadcrumbs
      shortDescription
      search
      searchPlaceholder
      banner
      bannerSmall
      widgetId
      newsId
      breadcrumbsId
      itemFeatureCollection
      enabled
      __typename
    }
  }
`;
export const updateFeatureCollectionTable = /* GraphQL */ `
  mutation UpdateFeatureCollectionTable(
    $input: UpdateFeatureCollectionTableInput!
    $condition: ModelFeatureCollectionTableConditionInput
  ) {
    updateFeatureCollectionTable(input: $input, condition: $condition) {
      id
      name
      type
      label
      title
      description
      featureFilters
      features
      pageId
      createdAt
      updatedAt
      metadata
      table
      featureCollectionId
      microservice
      resourceFrom
      pageBreadcrumbs
      shortDescription
      search
      searchPlaceholder
      banner
      bannerSmall
      widgetId
      newsId
      breadcrumbsId
      itemFeatureCollection
      enabled
      __typename
    }
  }
`;
export const deleteFeatureCollectionTable = /* GraphQL */ `
  mutation DeleteFeatureCollectionTable(
    $input: DeleteFeatureCollectionTableInput!
    $condition: ModelFeatureCollectionTableConditionInput
  ) {
    deleteFeatureCollectionTable(input: $input, condition: $condition) {
      id
      name
      type
      label
      title
      description
      featureFilters
      features
      pageId
      createdAt
      updatedAt
      metadata
      table
      featureCollectionId
      microservice
      resourceFrom
      pageBreadcrumbs
      shortDescription
      search
      searchPlaceholder
      banner
      bannerSmall
      widgetId
      newsId
      breadcrumbsId
      itemFeatureCollection
      enabled
      __typename
    }
  }
`;
export const createPageFeatureTable = /* GraphQL */ `
  mutation CreatePageFeatureTable(
    $input: CreatePageFeatureTableInput!
    $condition: ModelPageFeatureTableConditionInput
  ) {
    createPageFeatureTable(input: $input, condition: $condition) {
      id
      url
      title
      description
      type
      createdAt
      updatedAt
      metadata
      table
      pageId
      microservice
      resource
      pageBreadcrumbs
      shortDescription
      search
      searchPlaceholder
      banner
      bannerSmall
      widgetId
      newsId
      breadcrumbsId
      featureCollectionId
      enabled
      menu
      __typename
    }
  }
`;
export const updatePageFeatureTable = /* GraphQL */ `
  mutation UpdatePageFeatureTable(
    $input: UpdatePageFeatureTableInput!
    $condition: ModelPageFeatureTableConditionInput
  ) {
    updatePageFeatureTable(input: $input, condition: $condition) {
      id
      url
      title
      description
      type
      createdAt
      updatedAt
      metadata
      table
      pageId
      microservice
      resource
      pageBreadcrumbs
      shortDescription
      search
      searchPlaceholder
      banner
      bannerSmall
      widgetId
      newsId
      breadcrumbsId
      featureCollectionId
      enabled
      menu
      __typename
    }
  }
`;
export const deletePageFeatureTable = /* GraphQL */ `
  mutation DeletePageFeatureTable(
    $input: DeletePageFeatureTableInput!
    $condition: ModelPageFeatureTableConditionInput
  ) {
    deletePageFeatureTable(input: $input, condition: $condition) {
      id
      url
      title
      description
      type
      createdAt
      updatedAt
      metadata
      table
      pageId
      microservice
      resource
      pageBreadcrumbs
      shortDescription
      search
      searchPlaceholder
      banner
      bannerSmall
      widgetId
      newsId
      breadcrumbsId
      featureCollectionId
      enabled
      menu
      __typename
    }
  }
`;
