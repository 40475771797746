import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "@/graphql/mutations";
import { listProfileFeatures } from "@/graphql/queries";
import enterpriseData from "@/core/database/data/enterprise/enterprise_collection.json";
import { updateProfileFeature } from "@/core/database/data/profile/profile-graphql-mutations-operations.js";

async function generateEnterprise() {
  const enterpriseTableDetails = enterpriseData;
  enterpriseTableDetails.metadata = JSON.stringify(
    enterpriseTableDetails.metadata
  );
  createEnterpriseFeature(enterpriseTableDetails);
}

async function createEnterpriseFeature(enterpriseTableDetails) {
  try {
    const response = await API.graphql(
      graphqlOperation(mutations.createEnterpriseFeature, {
        input: enterpriseTableDetails,
      })
    );

    const createdRecordId = response.data.createEnterpriseFeature.id;
    console.log(createdRecordId);
    return createdRecordId;
  } catch (error) {
    console.error("Error retrieving data:", error);
  }
}

async function linkEnterpriseFeatureWithProfile(
  username,
  enterpriseTableDetails
) {
  console.log("1 - received enterprise");
  console.log(enterpriseTableDetails);

  try {
    const response = await API.graphql(
      graphqlOperation(listProfileFeatures, {
        filter: {
          auth_signup_id: {
            eq: username,
          },
          enabled: {
            eq: true,
          },
        },
      })
    );
    console.log("2 - get profile");
    console.log(response);

    let currentProfile;
    if (response.data.listProfileFeatures.items.length > 0) {
      currentProfile = response.data.listProfileFeatures.items[0];

      let currentProfileMetadata = JSON.parse(currentProfile.metadata);
      currentProfile.metadata = currentProfileMetadata;

      enterpriseTableDetails.metadata.owner.profile_id = currentProfile.id;
      enterpriseTableDetails.metadata.owner.username = currentProfile.username;
      enterpriseTableDetails.metadata.owner.displayName = currentProfile.name;
      enterpriseTableDetails.metadata = JSON.stringify(
        enterpriseTableDetails.metadata
      );

      try {
        const responseEnterpriseTable = await API.graphql(
          graphqlOperation(mutations.createEnterpriseFeature, {
            input: enterpriseTableDetails,
          })
        );

        const enterpriseCreated =
          responseEnterpriseTable.data.createEnterpriseFeature;

        console.log("4 - updated enterprise");
        console.log(enterpriseCreated);

        let enterpriseCreatedMetadata = JSON.parse(enterpriseCreated.metadata);
        enterpriseCreated.metadata = enterpriseCreatedMetadata;

        currentProfile.metadata.business_collection.push({
          business_id: enterpriseCreated.id,
          business_slugname: enterpriseCreated.slugname,
          business_whatsapp: enterpriseCreated.metadata.channels.whatsapp,
          business_website: enterpriseCreated.url,
          leadRelationship: enterpriseCreated.metadata.owner.leadRelationship,
        });

        console.log("3 - updated profile");
        console.log(currentProfile);

        currentProfile.metadata = JSON.stringify(currentProfile.metadata);

        updateProfileFeature(currentProfile);
      } catch (e) {
        console.log("Enterprise creating error: \n " + e);
      }
    }
  } catch (error) {
    console.error("Error retrieving data:", error);
  }

  return;
}

export {
  createEnterpriseFeature,
  generateEnterprise,
  linkEnterpriseFeatureWithProfile,
};
