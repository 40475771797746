import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "@/graphql/mutations";
import profileData from "@/core/database/data/profile/profile_collection.json";

async function generateProfile() {
  const profileTableDetails = profileData;
  profileTableDetails.metadata = JSON.stringify(profileTableDetails.metadata);
  createProfileFeature(profileTableDetails);
}

async function createProfileFeature(profileTableDetails) {
  try {
    const response = await API.graphql(
      graphqlOperation(mutations.createProfileFeature, {
        input: profileTableDetails,
      })
    );

    const createdRecordId = response.data.createProfileFeature;
    return createdRecordId;
  } catch (error) {
    console.error("Error retrieving data:", error);
  }
}

async function updateProfileFeature(toUpdate) {
  try {
    delete toUpdate["createdAt"];
    delete toUpdate["updatedAt"];
    delete toUpdate["_deleted"];
    delete toUpdate["_lastChangedAt"];
    delete toUpdate["__typename"];

    const response = await API.graphql({
      query: mutations.updateProfileFeature,
      variables: { input: toUpdate },
    });

    console.log(response);
  } catch (error) {
    console.error("Error updating data:", error);
  }
}

export { createProfileFeature, generateProfile, updateProfileFeature };
